//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "navigationDefault",
  computed: {
	  menu() {
		  return this.$store.state.global.content.navigationMain;
	  }
  }
};
