export const state = () => ({
	content: {},
	offscreenState: false,
})

export const mutations = {
	setGlobalContent(state, data) {
		state.content = data
	},
	setOffscreenState(state) {
		state.offscreenState = !state.offscreenState
	}
}
