//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import heroDefault from "../hero/heroDefault.vue"
import { mapMutations } from 'vuex'
import NavigationDefault from '../navigation/navigationDefault.vue'

export default 
{
	name: "headerDefault",
	props:{
		hero: Object,
		menu: Array,
		isError: Boolean
	},
	components: {
		heroDefault, NavigationDefault
	},
	methods: {
		...mapMutations({
			setOffscreenState: 'global/setOffscreenState'
		})
	}
}
