//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Kirbyimage from '../kirbyimage.vue' 
import Breadcrumb from '../navigation/breadcrumb.vue';
export default {
    name:"heroDefault",
    created: function()
    {
        
        let $checkboxes = this.$refs.filtergroup;
            this.$root.$emit('filterstate', $checkboxes);
    },
    mounted: function(){

        var mQuery = window.matchMedia("(min-width: 980px)");
        resizeHero(mQuery);


        if (!this.isError && !this.hero.isError && this.hero.showFilters === 'true')
        {

    

            let $checkboxes = this.$refs.filtergroup;
            let query = this.$route.query.filter;
            let allCheck = this.$refs.allCheckbox;

            if (!Array.isArray(query) && query != undefined)
            {
                query = new Array(query);
            }

            populateFilter(query, $checkboxes, allCheck);
            setFilter(query, $checkboxes);


            function populateFilter($checkboxes, $group, allCheck)
            {
                console.log("checked??", $checkboxes);


                if ($checkboxes == undefined)
                {
                    return true;
                }


                allCheck.checked = $checkboxes == undefined || $checkboxes.length == 0;

                if ($checkboxes != undefined && $group)
                {
                    $checkboxes.forEach(f => setFilter(f, $group));
                }
            }

            function setFilter($filter, $group){


                if ($filter == undefined)
                {
                    return true;
                }

                $group.querySelectorAll("input[type='checkbox']").forEach(c => 
                {
                    console.log("filter--", $filter);
                    if (c.id == $filter)
                    {
                        c.checked = true;
                    }
                });
            

            }


        }

        
        window.onresize = resizeHero(mQuery);
        
        function resizeHero(mQuery)
        {
            if (mQuery.matches)
            {

                let leftcol = document.querySelector('.hero-left');
                let rightcol = document.querySelector('.hero-right');
                if (leftcol && rightcol)
                    rightcol.style.height = leftcol.offsetHeight + 'px';
            }
        }

    },
    components:{        
        Kirbyimage,
        Breadcrumb
    },
    computed:{
    },
    props:{ 
        hero: Object,
        isError: Boolean
    },
    methods:
    {
        sendFilterState($filters)
        {
            this.$root.$emit('filterstate', $filters);
        },

        pickFilter(e){

            let $atfiltergroup = this.$refs.filtergroup;
             let filters = [];

             this.$refs.allCheckbox.checked = false;   
             if ($atfiltergroup != undefined)
             {
                $atfiltergroup.querySelectorAll("input[type='checkbox']:checked").forEach(f => {
                    filters.push(f.getAttribute('id'));
                })
             }



            this.$router.replace({ path:  this.$route.path, query: {filter: filters} });

            this.sendFilterState(filters);
        },
        toggleAll(e)
        {

            let allChecked = true;
            if (e.target.checked)
            {
                this.$router.replace({ path:  this.$route.path, query: {filter: []} });

                this.$refs.filtergroup.querySelectorAll("input[type='checkbox']").forEach(c => {
                   
                   if (!c.checked)
                   {
                       allChecked = false;
                   }

                   if (c !== e.target)
                   {
                    c.checked = false;                        
                   }
                   
                });

                
                if (allChecked == false)
                {
                    e.target.checked = true;
                }

                this.sendFilterState([]);
            }


        }        
    },
    data()
    {
        return{
            theme: this.hero.subheading != null ? 'explore-article' : ''
        }
    },
    
  errorCaptured(err, vm, info) {
      console.log("hero error", err, vm, info);
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    // TODO: Perform any custom logic or log to server
    // return false to stop the propagation of errors further to parent or global error handler
  }
}
