//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


	import { mapState } from 'vuex'
	import { mapMutations } from 'vuex'

	export default {
		name: 'layoutDefault',
		methods: {
			...mapMutations({
				setOffscreenState: 'global/setOffscreenState'
			})
		},
		computed: {
			...mapState({
				navigationFooter(state)
				{					
					return state.global.content.navigationFooter
				},
				navigationMain(state) {
					return state.global.content.navigationMain
				},
				offscreenState(state) {
					return state.global.offscreenState
				}
			})
		},
		async fetch() {
			if (process.server)
			{
			 	await this.$axios.$post('api/query', {
					query: "site",
					select: {
						title: "site.title",
						instagramhandle: "site.instagramhandle",
						getintouch: "site.getintouch",		
						signupurl: "site.signupurl",				
						sponsors: {"query": "site.sponsors.toFiles",
						"select" : {
							"url": true,
							"name": true }
						},
						navigationMain: "site.navigation_main.toNavigationArray",
						navigationFooter: "site.navigation_footer.toNavigationArray"
					}
				}).then(resp =>
     			{				
					this.$store.commit('global/setGlobalContent', resp.result)
				}).catch(e =>{
						console.log("navigation bar error", e);
				})
			}
		}
	}

