export const state = () => ({
	content: {},
	children: {},
	filters: {}
})

export const mutations = {
	setPageContent(state, data) {
		state.content = data;
		//console.log("STATE", data);
	},
	setPageFilters(state, data) {
		state.filters = data;
		//console.log("STATE", data);
	},
	setPageChildren(state, data) {
		state.children[data.key] = data.value
	},
}
