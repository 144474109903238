//
//
//
//
//
//
//
//
//


export default {
  props: { image: Object, CssClass: String, pictureCSS: String},
  methods:
  {
    
    transformURL: function($origin)
    {
      if (process.env.ENABLE_IMAGE_CDN)
      {
        let $base = process.env.IMAGE_BASE_URL;
        var reg = new RegExp($base, 'g');
          return $origin.replace(reg, process.env.IMAGE_CDN_HOST);
      }
      else
      {
        return process.env.IMAGE_BASE_URL + $origin;
      }
    }
  }
};
