//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  name: "Breadcrumb",
  methods: {
    notHomepage() {
      return this.$route.name !== "index" && this.$route.path != "/";
    },
  },
  props:{
      hero: Object
  },
  computed: {
    ...mapState({
      page(state) {
        return state.page.content;
      },
    }),
  },
};
